<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In the phase diagram for water, indicate the direction that the solid-liquid and the
        liquid-gas coexistence lines will move along the temperature axis after the addition of a
        solute.
      </p>

      <p class="mb-2">
        Solid-liquid coexistence line:
        <v-select
          v-model="inputs.input1"
          class="ml-2"
          style="display: inline-block; max-width: 300px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="A"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        Liquid-gas coexistence line:
        <v-select
          v-model="inputs.input2"
          class="ml-2"
          style="display: inline-block; max-width: 300px"
          :items="items1"
          item-text="text"
          item-value="value"
          label="B"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-4">
        <v-img style="max-width: 500px" src="/img/assignments/solidLiquidGasGraph.png" />
      </p>

      <p class="mb-2">
        What effect do impurities (or solutes) have on the melting point of a substance?
      </p>

      <p class="mb-2">
        Impurities:
        <v-select
          v-model="inputs.input3"
          class="ml-2"
          style="display: inline-block; max-width: 300px"
          :items="items2Shuffled"
          item-text="text"
          item-value="value"
          label="C"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        This colligative property is called:
        <v-select
          v-model="inputs.input4"
          class="ml-2"
          style="display: inline-block; max-width: 300px"
          :items="items3Shuffled"
          item-text="text"
          item-value="value"
          label="D"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LDA1S1Q5',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      items1: [
        {text: '$\\leftarrow$', value: 'left'},
        {text: '$\\rightarrow$', value: 'right'},
        {text: '$\\uparrow$', value: 'up'},
        {text: '$\\downarrow$', value: 'down'},
      ],
      items2: [
        {text: 'lower the melting point', value: 'lower'},
        {text: 'have no effect on the melting point', value: 'noEffect'},
        {text: 'raise the melting point', value: 'raise'},
      ],
      items3: [
        {text: 'boiling point elevation', value: 'elevation'},
        {text: 'freezing point depression', value: 'depression'},
        {text: 'osmotic pressure', value: 'osmotic'},
        {text: 'vapor pressure lowering', value: 'vapor'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    items2Shuffled() {
      return seededShuffle(this.items2, this.seed);
    },
    items3Shuffled() {
      return seededShuffle(this.items3, this.seed);
    },
  },
};
</script>
<style scoped></style>
